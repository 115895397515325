import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/nulogy.design/nulogy.design/src/components/MarkdownRenderer.js";
import { Page } from "@nulogy/components";
import { config } from "../../playgrounds/page";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Playground = makeShortcode("Playground");
const PropsTable = makeShortcode("PropsTable");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Page`}</h1>
    <p>{`There are three components we use to build up Nulogy's standard layout: An ApplicationFrame, a Page, and an optional Sidebar.`}</p>
    <p>{`The Page component wraps the actual content of a page, and provides consistent positioning for the Title and Breadcrumb components. This will be used many times, wrapping each page that gets created.`}</p>
    <Playground {...config} mdxType="Playground" />
    <h2>{`Props`}</h2>
    <PropsTable propsConfig={config.props} mdxType="PropsTable" />
    <h2>{`Related Components`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/components/branded-nav-bar"
        }}>{`BrandedNavBar`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/components/sidebar"
        }}>{`Sidebar`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/components/breadcrumbs"
        }}>{`Breadcrumbs`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      